import { FormikValues } from 'formik'
import { isString } from 'lodash-es'
import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { DeviceApi } from '@/api/common/deviceApi'
import { Device, Terminal } from '@/api/common/deviceApi/types'
import { DropDownInputsApi } from '@/api/common/dropDownInputsApi'
import { PosApi } from '@/api/sd/posApi'
import {
  CreatingPOSTicketFormConfig,
  EditingPOSTicketFormConfig,
  IexistingMerchants,
  POSServiceFormConfig,
  POSServiceFormParameter,
  TicketInfoFormData,
} from '@/api/sd/posApi/types'
import { TicketsApi } from '@/api/sd/ticketsApi'
import { AttachFilesResponse, ServiceCompany } from '@/api/sd/ticketsApi/types'
import { getServiceTypeFormData } from '@/components/blocks/POSRequestForm/utils'
import {
  FIELD_NAME_TERMINAL_IDS,
  FIELD_NAME_TERMINALS,
  POS_AQUIRER_LUNO,
  POS_TERMINAL_ID_AMEX,
} from '@/components/wrappers/AppNavigation/components/DrawerActions/components/modals/ServiceTypeModal/components/ServiceTypeForm/types'
import { DrawerActionsActions } from '@/constants/actions'
import { TicketsConfigResponse, Unit } from '@/types'
import { PopUpService } from '@/utils/services/popUpService'

import { getTicketDetailsResponse } from '../tickets/actions'
import {
  addPOSServiceTypeActionFail,
  addPOSServiceTypeActionResponse,
  addTicketResponse,
  addTicketResponseFail,
  clearNotSavedPOSServiceRequest,
  deletePosRequestItemFail,
  deletePosRequestItemResponse,
  deletePOSTicketRequestFail,
  deletePOSTicketRequestResponse,
  editPOSServiceTypeActionFail,
  editPOSServiceTypeActionResponse,
  editPosTicketRequestFail,
  editPosTicketRequestResponse,
  findUnitsAdvancedOrTerminalSearchTableRequest,
  findUnitsAdvancedOrTerminalSearchTableRequestFail,
  findUnitsByMerchantAndServiceResponse,
  findUnitsByMerchantAndServicetFail,
  findUnitsResponse,
  findUnitsResponseFail,
  getAdvancedUnitSearchConfigurationFail,
  getAdvancedUnitSearchConfigurationResponse,
  getCreatingPOSTicketEditFormConfigFail,
  getCreatingPOSTicketEditFormConfigResponse,
  getCreatingPOSTicketFormConfigFail,
  getCreatingPOSTicketFormConfigResponse,
  getExistingMerchantsResponse,
  getExistingMerchantsResponseFall,
  getMerchantFormConfigFail,
  getMerchantFormConfigResponse,
  getPOSServiceFormConfigFail,
  getPOSServiceFormConfigResponse,
  getServiceCompanyFail,
  getServiceCompanyResponse,
  getTicketInfoFormDataFail,
  getTicketInfoFormDataResponse,
  savePosTicketFail,
  savePosTicketResponse,
  sendPosTicketFail,
  sendPosTicketResponse,
  setIsSavePOSTicketSuccessfull,
} from './actions'

function* addTicket({ payload: { values } }: AnyAction): Generator<unknown, void, any> {
  try {
    const { attachments, requestNumber, description, unit, serviceCompanyId, claim } = values

    const response = yield call(TicketsApi.createTicket, {
      customerRequestNumber: requestNumber,
      description,
      deviceId: unit,
      serviceCompanyId,
      dataWayEnum: 'WEB',
      claim,
    })

    const {
      ticketDetails: {
        ticketInfo: { ticketId },
      },
    } = response

    let attachFiles: AttachFilesResponse = {}
    if (attachments.length) {
      attachFiles = yield call(TicketsApi.attachFiles, { files: attachments, ticketId })
    }

    PopUpService.showGlobalPopUp('translate#title.ticketSaveSuccessfully', 'success')

    const payload = { ...response.ticketDetails, attachedFiles: attachFiles?.files }

    yield put(addTicketResponse())
    yield put(getTicketDetailsResponse({ ticketDetails: payload }))
  } catch (error) {
    PopUpService.showGlobalPopUp(`Save ticket error.\n${error}`, 'error')
    yield put(addTicketResponseFail(error.message))
  }
}

function* findUnits({ payload: { str, byLuno } }: AnyAction): Generator<unknown, void, Unit[]> {
  try {
    const response = yield call(DropDownInputsApi.findDevices, str, byLuno)

    yield put(findUnitsResponse(response))
  } catch (error) {
    yield put(findUnitsResponseFail(error.message))
  }
}

function* findUnitsAdvanced({
  payload: { values },
}: AnyAction): Generator<unknown, void, Device[]> {
  try {
    const {
      partofterminaluid,
      cityid,
      partofluno,
      partofaddress,
      devicetypeid,
      renterid,
      merchantid,
    } = values

    const response = yield call(DeviceApi.getDevicesByAdvancedSearchParameters, {
      cityId: +cityid || null,
      deviceTypeId: devicetypeid || null,
      limit: 100,
      pageNumber: 1,
      merchantId: +merchantid || null,
      partOfAddress: partofaddress || null,
      partOfLuno: partofluno || null,
      partOfTerminalUid: partofterminaluid || null,
      renterId: +renterid || null,
    })

    yield put(findUnitsAdvancedOrTerminalSearchTableRequest(response))
  } catch (error) {
    yield put(findUnitsAdvancedOrTerminalSearchTableRequestFail(error.message))
  }
}

function* findServiceCompanies({
  payload: { deviceId },
}: AnyAction): Generator<unknown, void, ServiceCompany[]> {
  try {
    const response = yield call(TicketsApi.getServiceCompanies, deviceId)

    yield put(getServiceCompanyResponse(response))
  } catch (error) {
    yield put(getServiceCompanyFail(error.message))
  }
}

function* getAdvancedSearchFiltersConfig(): Generator<unknown, void, TicketsConfigResponse> {
  try {
    const response = yield call(TicketsApi.getFormConfig, {
      formUID: 'SRVTicket_DeviceAdvancedSearch',
      getConfigURL: '/search-form/config',
    })

    yield put(getAdvancedUnitSearchConfigurationResponse(response.formConfig))
  } catch (error) {
    yield put(getAdvancedUnitSearchConfigurationFail(error.message))
  }
}

function* getCreatingPOSTicketFormConfig(): Generator<unknown, void, CreatingPOSTicketFormConfig> {
  try {
    const response = yield call(PosApi.getPosTicketConfigFormData)

    yield put(getCreatingPOSTicketFormConfigResponse(response))
  } catch (error) {
    yield put(getCreatingPOSTicketFormConfigFail(error.message))
  }
}

const getPOSServiceFormFieldParameters = (displayObjectList: any): POSServiceFormParameter => {
  const fieldParameters: POSServiceFormParameter = {}

  const getFieldParameters = (item: any): void => {
    if (item.type && item.type !== 'FIELD') {
      return getFieldParameters(item.outputElement)
    }

    item.outObjects.forEach((outObj: any) => {
      if (outObj.type !== 'FIELD') {
        getFieldParameters(outObj.outputElement)
      } else {
        fieldParameters[outObj.outputElement.attributeName] = outObj.outputElement
      }
    })
  }

  displayObjectList.forEach((item: any) => {
    getFieldParameters(item.outputElement)
  })

  return fieldParameters
}

function* getPOSServiceFormConfigRequest({
  payload: { serviceTypeId, issuerCompanyId },
}: AnyAction): Generator<unknown, void, POSServiceFormConfig> {
  try {
    const responseConfig = yield call(
      PosApi.getPOSServiceFormConfig,
      serviceTypeId,
      issuerCompanyId,
    )

    const displayObjectList = responseConfig.displayObjectList.filter(
      (item: any) => item.outputElement.groupName !== 'POSCustomerSR_FADI_ConfDet',
    )
    const count = responseConfig.pageColumnCount

    const fieldParameters: POSServiceFormParameter = getPOSServiceFormFieldParameters(
      responseConfig.displayObjectList,
    )

    if (fieldParameters.poscarddataentryid) fieldParameters.poscarddataentryid.defaultValue = '1,2'

    yield put(
      getPOSServiceFormConfigResponse({
        displayObjectList,
        count,
        fieldParameters,
      }),
    )
  } catch (error) {
    yield put(getPOSServiceFormConfigFail(error.message))
  }
}

function* getMerchantFormConfigRequest({
  payload: { issuerCompanyId },
}: AnyAction): Generator<unknown, void, POSServiceFormParameter> {
  try {
    const { displayObjectList, pageColumnCount } = yield call(
      PosApi.getMerchantFormConfig,
      issuerCompanyId,
    )
    const fieldParameters: POSServiceFormParameter = getPOSServiceFormFieldParameters(
      displayObjectList,
    )

    yield put(
      getMerchantFormConfigResponse({ displayObjectList, pageColumnCount, fieldParameters }),
    )
  } catch (error) {
    yield put(getMerchantFormConfigFail(error.message))
  }
}

function* getPOSServiceEditFormConfigRequest({
  payload,
}: AnyAction): Generator<unknown, void, EditingPOSTicketFormConfig> {
  try {
    const response = yield call(PosApi.getEditConfigFormData, payload)

    yield put(getCreatingPOSTicketEditFormConfigResponse(response))
  } catch (error) {
    yield put(getCreatingPOSTicketEditFormConfigFail(error.message))
  }
}

function* addPOSServiceType({
  payload: { formValues, documentId, posServiceFormParameters, withGettingActualTicketInfo },
}: AnyAction): Generator {
  try {
    if (formValues[FIELD_NAME_TERMINALS]) {
      for (const item of formValues[FIELD_NAME_TERMINALS]) {
        const { id, acquirerLuno, deviceName } = item
        try {
          yield call(PosApi.addServiceTypeAction, documentId, {
            ...getServiceTypeFormData(formValues, posServiceFormParameters),
            deviceid: +id,
            [POS_AQUIRER_LUNO]: acquirerLuno || item,
            subject: deviceName || formValues.subject,
          })
        } catch (e) {
          throw new Error(e)
        }
      }
    }

    if (formValues[FIELD_NAME_TERMINAL_IDS]) {
      let i = 0
      for (const terminalId of formValues[FIELD_NAME_TERMINAL_IDS]) {
        if (terminalId) {
          try {
            if (
              formValues[FIELD_NAME_TERMINAL_IDS][i].posmerchantid !== '' &&
              formValues[FIELD_NAME_TERMINAL_IDS][i].posmerchantid !== null &&
              formValues[FIELD_NAME_TERMINAL_IDS][i].posmerchantid !== undefined
            ) {
              formValues.posmerchantid = formValues[FIELD_NAME_TERMINAL_IDS][i].posmerchantid
            }
            yield call(PosApi.addServiceTypeAction, documentId, {
              ...getServiceTypeFormData(formValues, posServiceFormParameters),
              [POS_AQUIRER_LUNO]: isString(terminalId) ? terminalId : terminalId[POS_AQUIRER_LUNO],
              ...(!isString(terminalId) && {
                [POS_TERMINAL_ID_AMEX]: terminalId[POS_TERMINAL_ID_AMEX],
              }),
            })
          } catch (e) {
            throw new Error(e)
          }
        }
        i++
      }
    }

    yield put(addPOSServiceTypeActionResponse())

    if (withGettingActualTicketInfo) {
      yield call(getPOSServiceEditFormConfigRequest as any, { payload: documentId })
    }
  } catch (error) {
    yield put(addPOSServiceTypeActionFail(error.message))
  }
}

function* editPOSServiceTypeAction({
  payload: { requestItemId, documentId, serviceTypeConfig },
}: AnyAction): SagaIterator {
  try {
    yield call(PosApi.editPOSRequestItem, requestItemId, documentId, serviceTypeConfig)
    yield put(editPOSServiceTypeActionResponse())

    yield call(getPOSServiceEditFormConfigRequest as any, { payload: documentId })
  } catch (error) {
    yield put(editPOSServiceTypeActionFail(error.message))
  }
}

function* sendPOSTicket({ payload: { documentId } }: AnyAction): SagaIterator {
  try {
    yield call(PosApi.sendPOSTicket, documentId)

    PopUpService.showGlobalPopUp('translate#title.ticketSentSuccessfully', 'success')

    yield put(sendPosTicketResponse())
  } catch (error) {
    yield put(sendPosTicketFail(error.message))
  }
}

function* validateCustomerRequestNumber(customerRequestNumber: string): SagaIterator {
  try {
    yield call(PosApi.validateCustomerRequestNumber, customerRequestNumber)
  } catch (error) {}
}

function* savePosTicketRequest({
  payload: { formData, withSendingTicket },
}: AnyAction): SagaIterator {
  try {
    const hasDocumentIdFromStore = yield select(
      state => !!state.drawerActions.saveTicketResponse.saveTicketData.documentId,
    )
    if (hasDocumentIdFromStore) {
      return yield put(setIsSavePOSTicketSuccessfull(true))
    }

    yield call(validateCustomerRequestNumber, formData.customerRequestNumber)

    const response = yield call(PosApi.savePosTicketRequest, formData)
    const {
      saveTicketData: { documentid: documentId },
    } = response

    yield put(savePosTicketResponse(response))

    PopUpService.showGlobalPopUp('translate#title.ticketSaveSuccessfully', 'success')

    const notSavedPOSServiceRequests: {
      formValues: FormikValues
      formParameters: POSServiceFormParameter
    }[] = yield select(state => state.drawerActions.notSavedPOSServiceRequests)

    if (notSavedPOSServiceRequests.length && documentId) {
      for (const { formValues, formParameters } of notSavedPOSServiceRequests) {
        yield call(addPOSServiceType as any, {
          payload: {
            formValues,
            documentId,
            posServiceFormParameters: formParameters,
            withGettingActualTicketInfo: false,
          },
        })
      }
    }

    if (withSendingTicket) {
      yield call(sendPOSTicket as any, { payload: { documentId } })
    } else {
      yield put(setIsSavePOSTicketSuccessfull(true))
    }

    yield put(clearNotSavedPOSServiceRequest())
  } catch (error) {
    yield put(savePosTicketFail(error.message))
  }
}

function* getTicketInfoFormDataRequest({
  payload: { ticketId },
}: AnyAction): Generator<unknown, void, TicketInfoFormData> {
  try {
    const response = yield call(PosApi.getTicketInfoFormDataRequest, ticketId)

    yield put(getTicketInfoFormDataResponse(response))
  } catch (error) {
    yield put(getTicketInfoFormDataFail(error.message))
  }
}

function* deletePosRequestItem({ payload }: AnyAction): Generator {
  try {
    yield call(PosApi.deletePosRequestItem, payload)

    yield put(deletePosRequestItemResponse())
  } catch (error) {
    yield put(deletePosRequestItemFail(error.message))
  }
}

function* editPosTicketRequest({
  payload: { documentId, data, withSendingTicket },
}: AnyAction): SagaIterator {
  try {
    const response = yield call(PosApi.editPosTicketRequest, documentId, data)

    yield put(editPosTicketRequestResponse(response))
    // yield put(getTicketsConfigRequest())

    if (withSendingTicket) {
      yield call(sendPOSTicket as any, { payload: { documentId } })
    }
  } catch (error) {
    yield put(editPosTicketRequestFail(error.message))
  }
}

function* deletePosTicket({ payload: { ticketId } }: AnyAction): Generator {
  try {
    yield call(PosApi.deletePOSTicketRequest, ticketId)

    yield put(deletePOSTicketRequestResponse())
    // yield put(getTicketsConfigRequest())
  } catch (error) {
    yield put(deletePOSTicketRequestFail(error.message))
  }
}

function* getTerminalsByMerchantAndService({
  payload: { merchantId, serviceId, data },
}: AnyAction): Generator<unknown, void, Terminal[]> {
  try {
    const response = yield call(
      DeviceApi.getFilteredDevicesByMerchantIdAndServiceId,
      merchantId,
      serviceId,
      data,
    )

    yield put(findUnitsByMerchantAndServiceResponse(response))
  } catch (error) {
    yield put(findUnitsByMerchantAndServicetFail(error.message))
  }
}

function* getExistingMerchants({
  payload,
}: AnyAction): Generator<unknown, void, IexistingMerchants> {
  try {
    const response = yield call(PosApi.getPosExistingMerchantList, payload)
    yield put(getExistingMerchantsResponse(response))
  } catch (error) {
    yield put(getExistingMerchantsResponseFall(error.message))
  }
}

export default function*(): Generator {
  yield takeLatest(DrawerActionsActions.FindUnitsRequest, findUnits)
  yield takeLatest(DrawerActionsActions.FindUnitsAdvancedRequest, findUnitsAdvanced)
  yield takeLatest(DrawerActionsActions.AddTicketRequest, addTicket)
  yield takeLatest(DrawerActionsActions.GetServiceCompanyRequest, findServiceCompanies)
  yield takeLatest(
    DrawerActionsActions.GetAdvancedUnitSearchConfigurationRequest,
    getAdvancedSearchFiltersConfig,
  )
  yield takeLatest(
    DrawerActionsActions.GetCreatingPOSFormConfigRequest,
    getCreatingPOSTicketFormConfig,
  )
  yield takeLatest(
    DrawerActionsActions.GetCreatingPOSEditFormConfigRequest,
    getPOSServiceEditFormConfigRequest,
  )
  yield takeLatest(
    DrawerActionsActions.GetPOSServiceFormConfigRequest,
    getPOSServiceFormConfigRequest,
  )
  yield takeLatest(DrawerActionsActions.SavePosTicketRequest, savePosTicketRequest)
  yield takeLatest(DrawerActionsActions.GetTicketInfoFormDataRequest, getTicketInfoFormDataRequest)
  yield takeLatest(DrawerActionsActions.AddPOSServiceTypeActionRequest, addPOSServiceType)
  yield takeLatest(DrawerActionsActions.DeletePosRequestItemRequest, deletePosRequestItem)
  yield takeLatest(DrawerActionsActions.EditPosTicketRequestRequest, editPosTicketRequest)
  yield takeLatest(DrawerActionsActions.EditPOSServiceTypeActionRequest, editPOSServiceTypeAction)
  yield takeLatest(DrawerActionsActions.GetMerchantConfigRequest, getMerchantFormConfigRequest)
  yield takeLatest(DrawerActionsActions.DeletePosTicketRequest, deletePosTicket)
  yield takeLatest(
    DrawerActionsActions.FindUnitsByMerchandAndServiceRequest,
    getTerminalsByMerchantAndService,
  )
  // yield takeLatest(DrawerActionsActions.SendPOSTicketRequest, sendPOSTicket)
  yield takeLatest(DrawerActionsActions.GetExistingMerchantsRequest, getExistingMerchants)
  yield takeLatest(DrawerActionsActions.SendPosTicketRequestRequest, sendPOSTicket)
}
